export const handleContactByEmail = (user: {
  properties: {
    firstname: { value: string };
    company: { value: string };
    phone: { value: string };
  };
  vid: string;
}) => {
  const url = `https://json.watermarksmartdesign.com/wmkhs-contact.php?vid=${user.vid}`;
  fetch(url)
    .then((response) => response.json())
    .then((data) => {
      const {
        status,
        //, message
      } = data;
      if (status === "error") {
        //setErrorMsg(message);
        console.log("error:", data);
      } else {
        if (status === "maybe_success") {
          // setIsGettingPhone(false);
          // setErrorMsg(null);
          // setSuccess(true);
        } else {
          // setErrorMsg("Something went wrong.");
          console.log("error");
        }
      }
    });
  return true;
};
