import React from "react";
import { useState, useEffect } from "react";
import {
  HubUserContext,
  isValidUser,
} from "../components/context/HubspotContext";
//import { navigate } from "gatsby";
import CreateReport from "../components/routes/CreateReport/CreateReport";
import { baseUrl, siteTitle, twitter } from "../vars/ThemeOptions";
import Layout from "../components/layout/MainLayout";
import { WmkSeo } from "wmk-seo";
const EsgReport = () => {
  const [validUser, setValidUser] = useState(false);

  useEffect(() => {
    if (window && localStorage?.getItem("hasCompletedForm")) {
      setValidUser(true);
    }
  }, []);
  return (
    <Layout>
      <WmkSeo.Meta
        title={`Create your ESG Report`}
        path={"/"}
        slug={"create-your-esg-report"}
        //description={description}
        ogImageUrl={""}
        twitterImageUrl={""}
        twitterHandle={twitter}
        siteTitle={siteTitle}
        baseUrl={baseUrl}
      />
      {/* <HubUserContext.Consumer>
        {(data) => {
          const { user } = data; */}
      {validUser ? <CreateReport /> : null}
      {/* }}
      </HubUserContext.Consumer> */}
    </Layout>
  );
};

export default EsgReport;
