import React, { MouseEventHandler } from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import { GatsbyImageData, Img, WmkImage } from "wmk-image";
import { colors } from "../../../vars/palette";

const StyledButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  color: ${colors.hex("white")};
  transition: all 0.4s ease;
  filter: saturate(1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  :hover {
    color: ${colors.hex("coaccent")};
    transition: all 0.3s ease;
    img {
      filter: saturate(2);
    }
  }
  span {
    margin-top: 10px;
  }
  @media only screen {
    &:focus {
      outline: none;
    }
  }
`;

const CreateReportActionButton = ({
  children,
  action,
  icon,
}: {
  children: string;
  action: MouseEventHandler;
  icon: {
    file: {
      contentType: string;
      url: string;
    };
    gatsbyImageData: GatsbyImageData;
    title: string;
  };
}) => {
  const image = new Img(icon);
  return (
    <Col>
      <StyledButton onClick={action}>
        {icon ? <WmkImage image={image} /> : null}
        {/* {icon ? <Img fixed={icon} alt={children} /> : null} */}
        <span>{children}</span>
      </StyledButton>
    </Col>
  );
};

export default CreateReportActionButton;
