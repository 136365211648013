import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CreateReportActionButton from "./CreateReportActionButton";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import PersonalizedHeader from "./PersonalizedHeader";
import PhoneInput from "./PhoneInput";
import { handleContactByEmail } from "./ContactByEmail";
import { colors } from "../../../vars/palette";
import { GlobalFieldsQuery } from "../../../fragments/NodeGlobalFields";
import { GatsbyImageData, Img, WmkImage } from "wmk-image";
// import { UserContext } from "../../context/UserContext";
// import { hubspotUserData } from "../../context/HubspotContext";

const BGImageWrapper = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  z-index: -1;
  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`;

const StyledContainer = styled(Container)`
  position: relative;
  height: 100vh;
  width: 100vw;
  & > .row {
  }
`;

const ContentRow = styled(Row)``;

const ContentWrapper = styled(Row)`
  background: ${colors.rgba("text", 0.8)};
  color: ${colors.hex("white")};
  padding: 4vh;
  height: 100vh;
`;

const H3 = styled.h3`
  margin: 4vh 0;
`;

interface userFormData {
  properties: {
    firstname: { value: string };
    company: { value: string };
    phone: { value: string };
  };
  vid: string;
}

const CreateReport = ({
  user,
}: {
  user: {
    properties:
      | {
          firstname: { value: string };
          company: { value: string };
          phone: { value: string };
        }
      // | hubspotUserData["user"]["properties"];
    vid: string;
  };
}) => {
  //const [phoneNumber, setPhoneNumber] = useState("");
  const [success, setSuccess] = useState();
  const [isGettingPhone, setIsGettingPhone] = useState(false);
  const [subhead, setSubhead] = useState("How would you like to get started?");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userCompany, setUserCompany] = useState("");

useEffect(() => {
  if (window && localStorage?.getItem("userFirstName")) {
    setFirstName(localStorage.getItem("userFirstName"))
  }
  if (window && localStorage?.getItem("userLastName")) {
    setLastName(localStorage.getItem("userLastName"))
  }
  if (window && localStorage?.getItem("userEmail")) {
    setEmail(localStorage.getItem("userEmail"))
  }
  if (window && localStorage?.getItem("userCompany")) {
    setUserCompany(localStorage.getItem("userCompany"))
  }
},[])

// useEffect(() => {
// console.log("firstName effect here", firstName)
// },[firstName])

  // console.log("user within", user);
  // const userData = useContext(UserContext)

  // console.log("userData", userData)
  // console.log("firstName", firstName)
  const firstname =
    firstName
      ? { firstname: firstName + ", t" }
      : { firstname: "T" };
  // console.log("firstname", firstname);
  const company =
    userCompany
      ? { company: " for " + userCompany + "." }
      : { company: "." };
  const {
    options,
    icons,
  }: {
    options: GlobalFieldsQuery;
    icons: {
      edges: {
        node: {
          copy: {
            text: string;
          };
          icon: {
            file: {
              contentType: string;
              url: string;
            };
            gatsbyImageData: GatsbyImageData;
            title: string;
          };
        };
      }[];
    };
  } = useStaticQuery(query);
  // const { mainImage } = options ? options : { options: null };
  // const { fluid } = mainImage ? mainImage : { fluid: null };
  const mainImg = new Img(options?.mainImage);
  const _icons = icons.edges.map((e) => e.node);

  const scheduleLink =
    "https://smart.watermarkadvertising.net/e2t/tc/VXh0TD2pmlclW3zpD504lvrPTW7lBRbf4djHjrN9k7Mbt3p_9LV1-WJV7CgQs9W5g6pLx5KDWbbW5cdDqR82QH2pW8knQ9S8j63XVN6BLnkn6dtyjW28bBLL8R5Kk1W8gQYMz4TxvrcW5LBDjX4lLsKmW2XtY-H26DXm1W4N72zY3-Lh1RW3X_mg39fVGW-N1YfVpRrcSvfW3MK0Q046m9ytV7gqKc7l1mmmN5J8CMXFdpzMN896L5Q8WnRtVz9WvK5Th2cMW7yDVmM4Jl48bW6tqCC74Mvv7BW6yz9kl2Pw5sRW700VVZ1j_ZGMN8SFqTD4d_PyW2QdD4R1t4t9fW8M0D0x8cl7nSN4Cywpm_FdWgW8BwjZp43qpxSN5X2gZHn4K7zW8-fqK-9grYkDW5Mcq9y2hSbSP3frh1";

  const buttonActions = {
    "Schedule a Meeting": () => {
      window.location.href = scheduleLink;
    },
    "Get a Call Back": () => {
      setIsGettingPhone(!isGettingPhone);
      setSubhead("Please enter your phone number.");
    },
    "Contact by Email": () => {
      if (
        "firstname" in user?.properties &&
        "company" in user?.properties &&
        "phone" in user?.properties
      ) {
        return handleContactByEmail(user as userFormData);
      }
    },
  };
  return (
    <StyledContainer fluid style={{ padding: 0, margin: 0 }}>
      {mainImg && (
        <BGImageWrapper>
          <WmkImage image={mainImg} />
          {/* <Img fluid={fluid} alt={mainImage.title} /> */}
        </BGImageWrapper>
      )}
      <ContentRow nogutters="true">
        <Col xs={12} md={7}>
          {/*just empty space*/}
        </Col>
        <Col xs={12} md={5}>
          <ContentWrapper className="flex-column">
            <Col>
              <PersonalizedHeader firstname={firstname?.firstname} company={company?.company} />
              <H3 className="raj">{subhead}</H3>

              {isGettingPhone || success ? (
                <PhoneInput
                  setIsGettingPhone={setIsGettingPhone}
                  user={user as userFormData}
                  success={success}
                  setSuccess={setSuccess}
                />
              ) : (
                <Row>
                  {_icons.map((i) => {
                    const { copy, icon } = i;
                    const { text } = copy ? copy : { text: null };
                    // const { fixed } = icon ? icon : { fixed: null };
                    //console.log(buttonActions, buttonActions[icon]);
                    return (
                      <CreateReportActionButton
                        icon={icon}
                        action={buttonActions[text as keyof object]}
                        key={text}
                      >
                        {text}
                      </CreateReportActionButton>
                    );
                  })}
                </Row>
              )}
            </Col>
          </ContentWrapper>
        </Col>
      </ContentRow>
    </StyledContainer>
  );
};

export default CreateReport;

const query = graphql`
  {
    options: contentfulGlobal(title: { eq: "Create a Report" }) {
      ...NodeGlobalFields
    }
    icons: allContentfulIconText(
      filter: { title: { regex: "/Create Report/" } }
      sort: { order: ASC, fields: sortOrder }
      limit: 2
    ) {
      edges {
        node {
          copy: text {
            text
          }
          icon {
            file {
              contentType
              url
            }
            gatsbyImageData(width: 83, height: 83, quality: 80)
            title
          }
        }
      }
    }
  }
`;
