import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { colors } from "../../../vars/palette";

const PhoneInput = ({
  user,
  setIsGettingPhone,
  success,
  setSuccess,
}: {
  user: { properties: { phone: { value: string } }; vid: string };
  setIsGettingPhone: Function;
  success: boolean;
  setSuccess: Function;
}) => {
  const [phoneNumber, setPhoneNumber] = useState(
    user &&
      user.properties &&
      user.properties.phone &&
      user.properties.phone.value
  );
  const [errorMsg, setErrorMsg] = useState();
  // console.log(
  //   user &&
  //     user.properties &&
  //     user.properties.phone &&
  //     user.properties.phone.value,
  //   user
  // );
  const handlePhoneInput = (e) => {
    setPhoneNumber(e.target.value);
  };
  const handlePhoneSubmit = (e) => {
    const _phone = phoneNumber ? phoneNumber.replace(/\D/g, "") : "";
    //console.log("sending:", _phone);
    e.preventDefault();
    const url = `https://json.watermarksmartdesign.com/wmkhs-contact.php?vid=${user.vid}&phone=${_phone}&wants_esg_callback=1`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        const { status, message } = data;
        if (status === "error") {
          setErrorMsg(message);
        } else {
          if (status === "maybe_success") {
            setIsGettingPhone(false);
            setErrorMsg(null);
            setSuccess(true);
          } else {
            setErrorMsg("Something went wrong.");
          }
        }
      });
  };
  return !success ? (
    <Form onSubmit={handlePhoneSubmit}>
      <Form.Group controlId="phone">
        <Form.Label>
          Add or update your number below and we'll give you a call during our
          normal business hours.{" "}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter phone number"
          value={phoneNumber}
          onChange={handlePhoneInput}
        />
        {errorMsg ? (
          <Form.Text>
            <span style={{ color: "red", margin: ".75vh 0", display: "block" }}>
              Error: {errorMsg}
            </span>
          </Form.Text>
        ) : null}
        <Button type="submit">Submit</Button>
      </Form.Group>
    </Form>
  ) : (
    <p>
      Thank you! Heathe from Watermark will reach out to you soon! In the
      meantime,{" "}
      <a href="https://wmkagency.com" style={{ color: colors.hex("primary") }}>
        check out our website
      </a>
      !
    </p>
  );
};

export default PhoneInput;
