import React from "react";
import styled from "styled-components";

const StyledOops = styled.div`
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.45);
  text-align: right;
  cursor: pointer;
`;

const Oops = () => {
  return (
    <StyledOops>
      <u>Oops! This isn't me.</u>
    </StyledOops>
  );
};

export default Oops;
