import React from "react";
import styled from "styled-components";
import { colors } from "../../../vars/palette";
import Oops from "./Oops";

const StyledHeadline = styled.h1``;

const StyledHorizontalRule = styled.hr`
  border-color: ${colors.hex("secondary")};
  background: ${colors.hex("secondary")};
  opacity: 1;
`;

const PersonalizedHeader = ({
  firstname,
  company,
}: {
  firstname: string;
  company: string;
}) => {
  return (
    <>
      <StyledHeadline className="raj">
        {firstname}hank you for your interest in creating an ESG Report
        {company}
      </StyledHeadline>
      <Oops />
      <StyledHorizontalRule />
    </>
  );
};

export default PersonalizedHeader;
